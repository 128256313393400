<template>
  <ValidationObserver ref="form">
    <v-form @submit.prevent="handleSubmitForm">
      <v-card :disabled="loading">
        <v-card-title>
          {{ __("Detalhes do apuramento", "budget") }}
          <v-spacer></v-spacer>
          <v-btn @click="handleClose()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            name="participant_id"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="input_participant_id"
              name="participant_id"
              :label="__('Participante', 'budget')"
              :items="participants"
              item-value="id"
              item-text="contact.name"
              :error-messages="errors"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.contact.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </ValidationProvider>
          <ValidationProvider
            name="coverage_id"
            rules="required"
            v-slot="{ errors }"
          >
            <v-autocomplete
              v-model="input_coverage_id"
              name="coverage_id"
              :label="$capitalize($tc('model.damages_coverage'))"
              :items="coverages"
              item-text="type_description"
              item-value="id"
              :error-messages="errors"
            ></v-autocomplete>
          </ValidationProvider>
          <v-checkbox
            v-model="input_is_research"
            :label="__('Pesquisa de avarias', 'budget')"
          ></v-checkbox>
          <v-select
            v-model="input_approved"
            :label="$capitalize($tc('model.visit_report_framing_check_box'))"
            :items="fitting_items"
          ></v-select>
          <ValidationProvider
            name="estimated_value"
            :rules="{
              required: input_approved === null,
              min_value: input_approved === null ? 0.01 : 0,
            }"
            v-slot="{ errors }"
          >
            <MoneyInput
              v-model="input_estimated_value"
              name="estimated_value"
              :label="__('Valor estimado', 'budget')"
              :error-messages="errors"
            ></MoneyInput>
          </ValidationProvider>
          <ValidationProvider
            name="value"
            :rules="{
              required: true,
              min_value: input_approved === true ? 0.01 : 0,
            }"
            v-slot="{ errors }"
          >
            <MoneyInput
              v-model="input_value"
              name="value"
              :label="__('Valor apurado', 'budget')"
              :readonly="input_approved === false || input_approved === null"
              :error-messages="errors"
            ></MoneyInput>
          </ValidationProvider>
          <v-row>
            <v-col cols="6" sm="4">
              <ValidationProvider
                name="franchise_value"
                rules="min_value:0.00"
                v-slot="{ errors }"
              >
                <MoneyInput
                  v-model="input_franchise_value"
                  name="franchise_value"
                  :label="__('Valor da franquia', 'budget')"
                  :readonly="
                    input_approved === false || input_approved === null
                  "
                  :error-messages="errors"
                ></MoneyInput>
              </ValidationProvider>
            </v-col>
            <v-col>
              <MoneyInput
                v-model="value_with_franchise"
                name="franchise_value"
                :label="__('Valor a enviar', 'budget')"
                readonly
              ></MoneyInput>
            </v-col>
          </v-row>
          <ValidationProvider
            name="type_id"
            rules="required"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="input_type_id"
              :label="__('Tipo', 'budget')"
              name="type_id"
              :error-messages="errors"
            >
              <v-radio :label="__('Edifício', 'budget')" :value="1"> </v-radio>
              <v-radio :label="__('Conteúdo', 'budget')" :value="2"> </v-radio>
            </v-radio-group>
          </ValidationProvider>
          <ValidationProvider
            name="budget_objective"
            rules="required"
            v-slot="{ errors }"
          >
            <v-radio-group
              v-model="budget_objective"
              name="budget_objective"
              :label="__('Objetivo', 'budget')"
              :error-messages="errors"
            >
              <v-radio :label="__('Reparação', 'budget')" :value="2"> </v-radio>
              <v-radio :label="__('Indemnização', 'budget')" :value="3">
              </v-radio>
            </v-radio-group>
          </ValidationProvider>
          <v-row>
            <v-col cols="6" sm="4">
              <ValidationProvider
                name="iva"
                rules="required|min_value:0"
                v-slot="{ errors }"
              >
                <v-combobox
                  name="iva"
                  v-model="inputed_iva"
                  :label="__('Escolha ou digite o IVA (%)', 'budget')"
                  :items="ivas"
                  :error-messages="errors"
                ></v-combobox>
              </ValidationProvider>
            </v-col>
            <v-col>
              <MoneyInput
                :value="total_iva"
                :label="__('Total do iva', 'budget')"
                readonly
              ></MoneyInput>
            </v-col>
          </v-row>
          <ValidationProvider
            name="receiving_participant_id"
            :rules="{
              required: budget_objective === 3,
            }"
            v-slot="{ errors }"
          >
            <v-select
              name="receiving_participant_id"
              v-model="input_receiver_entit_id"
              label="Entidade recebedora"
              :items="participants"
              item-value="id"
              item-text="contact.name"
              v-show="budget_objective === 3"
              :error-messages="errors"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.contact.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.type.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="handleClose()" text>
            {{ $capitalize($tc("model.damages_cancel_button")) }}
          </v-btn>
          <v-btn :loading="loading" type="submit" color="primary">
            {{ $capitalize($tc("model.damages_save_button")) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
const REPARATION_ID = 2;
const COMPENSATION_ID = 3;

import MoneyInput from "@/components/MoneyInput";
import i18n from "@/mixins/i18n";

export default {
  name: "established-budget-form",
  components: { MoneyInput },
  mixins: [i18n],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit_id: null,
      input_value: 0,
      input_estimated_value: 0,
      input_franchise_value: 0,
      input_receiver_entit_id: null,
      input_participant_id: null,
      input_is_research: false,
      input_coverage_id: null,
      input_type_id: null,
      input_approved: null,
      iva: 23,
      ivas: [23, 0],
      budget_objective: REPARATION_ID,
      fitting_items: [
        {
          text: this.__("A definir", "budget"),
          value: null,
        },
        {
          text: this.$capitalize(this.$tc("model.products_framed")),
          value: true,
        },
        {
          text: this.$capitalize(this.$tc("model.products_unframed")),
          value: false,
        },
      ],
    };
  },
  methods: {
    setErrors(errors) {
      this.$refs.form.setErrors(errors);
    },
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.$emit("save", this.getPayload());
    },
    getPayload() {
      return {
        established_budget: {
          id: this.edit_id,
          policy_coverage_id: this.input_coverage_id,
          type_id: this.input_type_id,
          objective_id: this.budget_objective,
          description: null,
          value: this.input_value,
          franchise_value: this.input_franchise_value,
          vat_percentage: this.iva,
          receiving_participant_id: this.input_receiver_entit_id,
          approved: this.input_approved,
          participant_id: this.input_participant_id,
          estimated_value: this.input_estimated_value,
          is_research: this.input_is_research,
        },
      };
    },
    resetForm() {
      this.edit_id = null;
      this.input_value = 0;
      this.input_estimated_value = 0;
      this.input_franchise_value = 0;
      this.budget_objective = REPARATION_ID;
      this.iva = 23;
      this.input_coverage_id = null;
      this.input_is_research = false;
      this.input_type_id = null;
      this.input_approved = null;
      this.input_receiver_entit_id = null;
      this.input_participant_id =
        this.participants.length === 1 ? this.participants[0].id : null;

      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    fillForm(budget) {
      this.edit_id = budget.id;
      this.input_coverage_id = budget.policy_coverage_id;
      this.input_type_id = budget.type_id;
      this.budget_objective = budget.objective_id;
      this.input_value = budget.value;
      this.iva = budget.vat_percentage;
      this.input_receiver_entit_id = budget.receiving_participant_id;
      this.input_approved = budget.approved;
      this.input_participant_id = budget.participant_id;
      this.input_estimated_value = budget.estimated_value;
      this.input_is_research = budget.is_research;
      this.input_franchise_value = budget.franchise_value || 0;
    },
    handleClose() {
      this.$emit("close");
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    inputed_iva: {
      get() {
        return this.iva;
      },
      set(value) {
        this.iva = isNaN(value) ? 0 : value;
      },
    },
    total_iva: function () {
      const value = this.input_value || 0;
      const iva = this.inputed_iva;
      if (!iva) return 0;
      return (value * iva) / 100;
    },
    value_with_franchise: function () {
      const value = Number(this.input_value || 0);
      const value_with_franchise = value - (this.input_franchise_value || 0);
      return value_with_franchise < 0 ? 0 : value_with_franchise;
    },
    participants() {
      return this.lead.participants.filter(
        (participant) => participant.insured == true
      );
    },
    coverages() {
      if (!this.lead) return [];

      return this.lead.policy.coverages.filter(
        (item) => item.selected === true
      );
    },
  },
  watch: {
    input_approved: function (value) {
      if (value === false) {
        this.input_value = 0;
        this.input_franchise_value = 0;
      } else if (value === null) {
        this.input_value = 0;
        this.input_franchise_value = 0;
      }
    },
    lead: function (value) {
      if (!value) return;

      if (value.participants.length === 1) {
        this.input_participant_id = value.participants[0].id;
      }
    },
    input_coverage_id: function (value) {
      if (!value) return;

      const coverage = this.lead.policy.coverages.find(
        (coverage) => coverage.id === value
      );
      const coverage_type = coverage.type_description.toLowerCase();

      if (!this.input_is_research) {
        this.input_is_research =
          coverage_type.includes("pesquisa") || coverage_type.includes("pesq.");
      }
    },
    budget_objective: function (value) {
      if (value === COMPENSATION_ID) {
        this.inputed_iva = 0;
        if (this.input_receiver_entit_id === null) {
          this.input_receiver_entit_id = this.input_participant_id;
        }
      } else if (value === REPARATION_ID) {
        this.inputed_iva = this.inputed_iva == 0 ? 23 : this.inputed_iva;
      }
    },
  },
};
</script>
