<style lang="scss">
#budgets_totals {
  .empty {
    width: 100%;
    text-align: center;
    padding: 50px;
    color: rgb(133, 133, 133);
  }
}
</style>
<template>
  <v-card id="budgets_totals" outlined :loading="silent_loading" class="flex-grow-1">
    <v-skeleton-loader
      v-if="fetching_budgets"
      type="card-heading, table-tbody"
    ></v-skeleton-loader>
    <div v-else>
      <v-card-title>
        {{ __('Totais', 'budget') }}
      </v-card-title>
      <div class="empty" v-if="budget_totals.per_business_entity.length == 0">
        <v-img src="@/assets/box.svg" contain height="100px" class="mb-2" />
        {{ __('Não há orçamentos para a totalização', 'budget') }}  <br />
      </div>

      <v-list dense v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> {{ __('Total apurado', 'budget') }} </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="total-value">
            {{ formatMoney(budget_totals.established_loss) }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> {{ __('IVA', 'budget') }} </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="total-value">
            {{ formatMoney(budget_totals.iva_value) }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> {{ __('Franquia', 'budget') }} </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="total-value">
            {{ formatMoney(budget_totals.total_applicable_franchise || 0) }}
          </v-list-item-action>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="">
              <b>{{ __('Total apurado c/ IVA', 'budget') }}</b>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="total-value">
            {{ formatMoney(budget_totals.established_loss_with_iva) }}
          </v-list-item-action>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="">
              <b>{{ __('Total a ser indemnizado', 'budget') }}</b>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="total-value">
            {{ formatMoney(budget_totals.compensation_total) }}
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-card>
</template>

<script>
import MoneyFormatter from "@/tools/money";
import i18n from '@/mixins/i18n';

export default {
  props: ["budget_totals", "fetching_budgets", "silent_loading"],
  mixins: [i18n],
  methods: {
    formatMoney(value) {
      return MoneyFormatter.euro(value);
    },
  }
}
</script>

<style>

</style>