<style lang="scss">
#established_budgets {
  .deleting {
    color: var(--v-error-base);
  }

  .clickable {
    cursor: pointer;
  }

  .empty {
    width: 100%;
    text-align: center;
    padding: 50px;
    color: rgb(133, 133, 133);
  }
}
</style>
<template>
  <v-row id="established_budgets">
    <v-col>
      <v-skeleton-loader
        v-if="fetching_budgets"
        type="card-heading, table-tbody"
      ></v-skeleton-loader>
      <v-card :loading="silent_loading" v-else outlined>
        <v-card-title v-if="select_mode === false" flat>
          {{ __('Estimativa/apuramento', 'budget') }}
          <v-spacer></v-spacer>
          <v-btn @click="select_mode = true" icon v-if="budgets.length">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn @click="handleOpenNewBudget" outliend color="primary">
            <v-icon left>mdi-plus</v-icon>{{ __('Registrar', 'budget') }}
          </v-btn>
        </v-card-title>
        <v-toolbar v-else flat color="primary" dark>
          <v-toolbar-title>
            {{ selected_budgets.length }} {{ __('Selecionado(s)', 'budget') }}   
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="handleCancelSelectMode"
            text
            :disabled="deleting"
          >
            {{ __('Cancelar', 'budget') }}   
          </v-btn>
          <v-btn
            @click="destroyBudgets()"
            color="error"
            :loading="deleting"
          >
            <v-icon left>mdi-delete</v-icon> {{ __('Excluir', 'budget') }} 
          </v-btn>
        </v-toolbar>

        <div class="empty" v-if="budgets.length == 0">
          <v-img src="@/assets/box.svg" contain height="100px" class="mb-2" />
         {{ __('Nenhum registro encontrado', 'budget') }} <br />
          <v-btn
            @click="handleOpenNewBudget()"
            class="mt-3"
            color="primary"
            text
            >{{ __('Registrar', 'budget') }}</v-btn
          >
        </div>
        <v-simple-table v-else>
          <thead>
            <tr>
              <th v-if="select_mode"></th>
              <th></th>
              <th width="200px">{{ __('Participante', 'budget') }}</th>
              <th>{{ __('Cobertura', 'budget') }}</th>
              <th>{{ __('Objetivo', 'budget') }}</th>
              <th>{{ __('Estimado', 'budget') }}</th>
              <th>{{ __('Apurado', 'budget') }}</th>
              <th>{{ __('Franquia', 'budget') }}</th>
              <th>{{ __('IVA', 'budget') }}</th>
              <th>{{ __('Enviar valor', 'budget') }}</th>
              <th>Registado em</th>
              <th>Última alteração</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="{ deleting: isBudgetSelected(budget.id) }"
              v-for="budget in budgets"
              :key="budget.id"
              class="clickable"
              @click="handleEditBudget(budget)"
            >
              <td v-if="select_mode">
                <v-simple-checkbox
                  @input="handleSelectBudget(budget.id)"
                  :value="isBudgetSelected(budget.id)"
                  color="error"
                  :disabled="deleting"
                ></v-simple-checkbox>
              </td>
              <td class="text-center">
                <v-icon v-if="budget.approved === true" color="green">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else-if="budget.approved === false" color="red">
                  mdi-close-box
                </v-icon>
                <v-icon v-else>
                  mdi-help-box
                </v-icon>
              </td>
              <td>
                <span 
                  class="d-inline-block text-truncate"
                  style="max-width: 190px"
                >
                  {{ budget.participant_name }}
                </span>
              </td>
              <td>{{ budget.coverage.type_description }}</td>
              <td>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      :class="{ 'error--text': isBudgetSelected(budget.id) }"
                    >
                      {{ budget.objective.description }} •
                      {{ budget.type.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span 
                        class="d-inline-block text-truncate"
                        style="max-width: 150px"
                      >{{ budget.receiver.name }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>{{ formatMoney(budget.estimated_value) }}</td>
              <td>{{ formatMoney(budget.value) }}</td>
              <td>{{ formatMoney(budget.franchise_value || 0) }}</td>
              <td>{{ budget.vat_percentage }}%</td>
              <td>{{ formatMoney(getValueWithFranchise(budget)) }}</td>
              <td>{{ budget.created_at }}</td>
              <td>{{ budget.updated_at }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>

    <v-dialog v-model="budget_dialog" max-width="650" eager persistent>
      <established-budget-form
        ref="budget_form"
        :loading="submitting"
        @save="handleSubmitForm"
        @close="budget_dialog = false"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import MoneyFormatter from "@/tools/money";
import { store, update, destroy } from "@/services/established_budgets";
import i18n from '@/mixins/i18n';

import EstablishedBudgetForm from "@/components/Budget/EstablishedBudgetForm";

export default {
  components: { EstablishedBudgetForm },
  mixins: [i18n],
  props: [
    'budgets', 
    'silent_loading',
    'fetching_budgets'
  ],
  data() {
    return {
      // Flags
      deleting: false,
      submitting: false,
      select_mode: false,
      budget_dialog: false,
      
      // Budget Selection
      selected_budgets: [],

      validationErrors: {}
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    lead_number() {
      return this.$route.params["lead_number"];
    }
  },
  methods: {
    // Budget Selection For DELETE
    handleCancelSelectMode() {
      this.selected_budgets = [];
      this.select_mode = false;
    },
    handleSelectBudget(id) {
      if (this.isBudgetSelected(id)) {
        this.selected_budgets = this.selected_budgets.filter((budget) => budget !== id);
      } else {
        this.selected_budgets.push(id);
      }
    },
    isBudgetSelected(id) {
      return this.selected_budgets.some((budget) => budget === id);
    },

    // Budget Form
    handleEditBudget(budget) {
      if (this.select_mode) return;

      this.$refs.budget_form.fillForm(budget);
      this.budget_dialog = true;
    },
    handleOpenNewBudget() {
      this.$refs.budget_form.resetForm();

      this.budget_dialog = true;
    },

    // Requests
    async handleSubmitForm(payload) {
      this.submitting = true;
      try {
        if (payload.established_budget.id) {
          await this.updateBudget(payload);
        } else {
          await this.createBudget(payload);
        }

        this.$listeners.refreshBudgets(true);
        this.$listeners.refreshTotals();

        this.budget_dialog = false;
      } catch (error) {
        console.error(error);
        if(error.response.data.errors) {
          this.$refs.budget_form.setErrors(error.response.data.errors)
        }
      } finally {
        this.submitting = false;
      }
    },
    async createBudget(payload) {
      const response = await store(this.lead_number, payload);

      return response;
    },
    async updateBudget(payload) {
      const response = await update(
        this.lead_number,
        payload.established_budget.id,
        payload
      );

      return response;
    },
    async destroyBudgets() {
      const payload = {
        ids: this.selected_budgets,
      };

      this.deleting = true;
      await destroy(this.lead_number, payload);
      await this.$listeners.refreshBudgets(true);
      await this.$listeners.refreshTotals();
      this.deleting = false;

      this.handleCancelSelectMode();
    },


    formatMoney(value) {
      return MoneyFormatter.euro(value);
    },

    getValueWithFranchise(budget) {
      const value = budget.value || 0;

      const value_with_franchise = value - (budget.franchise_value || 0);
      return value_with_franchise < 0 ? 0 : value_with_franchise;
    }
  }
}
</script>

<style>

</style>