import Vue from "vue";

export async function index(lead_number, payload) {
  const response = await Vue.prototype.$http.get(
    `leads/${lead_number}/external_budgets`
  );

  return response.data;
}

export async function store(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/external_budgets`,
    payload
  );

  return response.data;
}

export async function update(lead_number, budget_id, payload) {
  const response = await Vue.prototype.$http.put(
    `leads/${lead_number}/external_budgets/${budget_id}`,
    payload
  );

  return response.data;
}

export async function approve(participant_id, budget_id, approved) {
  const response = await Vue.prototype.$http.put(
    `participants/${participant_id}/external_budgets/${budget_id}`,
    {
      external_budget: {
        approved
      }
    }
  );

  return response.data;
}
export async function destroy(lead_number, payload) {
  const response = await Vue.prototype.$http.post(
    `leads/${lead_number}/external_budgets/destroy`,
    payload
  );

  return response;
}

export async function uploadAttachment(participant_id, budget_id, attachment) {
  const formData = new FormData();
  formData.append("attachment", attachment);
  formData.append("attachment_id", attachment?.id ? attachment.id : "");

  const response = await Vue.prototype.$http.post(
    `participants/${participant_id}/external_budgets/${budget_id}/upload`,
    formData
  );

  return response.data;
}
