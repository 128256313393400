<style lang="scss">
#budgets_business_entities {
  .empty {
    width: 100%;
    text-align: center;
    padding: 50px;
    color: rgb(133, 133, 133);
  }
}
</style>
<template>
  <v-card outlined :loading="silent_loading" id="budgets_business_entities" class="flex-grow-1">
    <v-skeleton-loader
      v-if="fetching_budgets"
      type="card-heading, table-tbody"
    ></v-skeleton-loader>
    <div v-else>
      <v-card-title>
        {{ __('Entidades recebedoras', 'budget') }}
      </v-card-title>
      <div class="empty" v-if="budgets_by_business_entity.length == 0">
        <v-img src="@/assets/box.svg" contain height="100px" class="mb-2" />
        {{ __('Não existem orçamentos para contabilizar os totais por entidades recebedoras', 'budget') }} <br />
      </div>

      <v-simple-table v-else>
        <thead>
          <tr>
            <th> {{ __('Entidades recebedoras', 'budget') }} </th>
            <th> {{ __('Apurado', 'budget') }} </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="business_entity in budgets_by_business_entity"
            :key="business_entity.business_entity"
            class="clickable"
          >
            <td >{{ business_entity.name }}</td>
            <td>{{ formatMoney(business_entity.established_loss) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </v-card>
</template>

<script>
import MoneyFormatter from "@/tools/money";
import i18n from '@/mixins/i18n';

export default {
  props: ["budgets_by_business_entity", "fetching_budgets", "silent_loading"],
  mixins: [i18n],
  methods: {
    formatMoney(value) {
      return MoneyFormatter.euro(value);
    },
  }
}
</script>

<style>

</style>