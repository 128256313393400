<style lang="scss">
#external_budgets {
  .deleting {
    color: var(--v-error-base);
  }

  .clickable {
    cursor: pointer;
  }

  .empty {
    width: 100%;
    text-align: center;
    padding: 50px;
    color: rgb(133, 133, 133);
  }
}
</style>
<template>
  <v-row id="external_budgets">
    <v-col>
      <v-skeleton-loader
        v-if="fetching_budgets"
        type="card-heading, list-item-two-line, table-tbody"
      ></v-skeleton-loader>
      <v-card v-else outlined :loading="silent_loading">
        <v-card-title v-if="!select_mode">
          {{ __('Orçamentos externos', 'budget') }}
          <v-spacer></v-spacer>
          <v-btn @click="select_mode = true" icon v-if="external_budgets.length">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn @click="handleOpenNewBudget" color="primary">
            <v-icon left>mdi-plus</v-icon> {{ __('Registrar', 'budget') }}
          </v-btn>
        </v-card-title>
        <v-toolbar v-else flat color="primary" dark>
          <v-toolbar-title>
            {{ selected_budgets.length }} {{ __('Selecionado(s)', 'budget') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="handleCancelSelectMode"
            text
            :disabled="deleting"
          >
            {{ __('Cancelar', 'budget') }}
          </v-btn>
          <v-btn
            @click="destroyBudgets()"
            color="error"
            :loading="deleting"
          >
            <v-icon left>mdi-delete</v-icon> {{ __('Excluir', 'budget') }}
          </v-btn>
        </v-toolbar>
        <v-card-subtitle class="mt-2">
         {{ __("Orçamentos externos são valores contidos nos orçamentos enviados pelos participantes. Estes formarão o valor o prejuízo reclamado no relatório final", 'budget') }}
        </v-card-subtitle
        >

        <div class="empty" v-if="external_budgets.length == 0">
          <v-img src="@/assets/box.svg" contain height="100px" class="mb-2" />
          {{ __('Não existem orçamentos externos', 'budget') }} <br />
          <v-btn
            @click="handleOpenNewBudget"
            class="mt-3"
            color="primary"
            text
          >
            {{ __('Registrar', 'budget') }}
          </v-btn>
        </div>

        <v-simple-table v-else>
          <thead>
            <tr>
              <th v-if="select_mode"></th>
              <th> {{ __('Participante', 'budget') }} </th>
              <th> {{ __('Orçamento', 'budget') }}</th>
              <th> {{ __('Cobertura', 'budget') }}</th>
              <th> {{ __('Valor', 'budget') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="external_budget in external_budgets"
              :key="external_budget.id"
              @click="handleEditBudget(external_budget)"
              class="clickable"
            >
              <td v-if="select_mode">
                <v-simple-checkbox
                  color="danger"
                  @input="handleSelectBudget(external_budget.id)"
                  :value="isBudgetSelected(external_budget.id)"
                ></v-simple-checkbox>
              </td>
              <td>{{ external_budget.participant.contact.name }}</td>
              <td>
                <a class="link" v-if="external_budget.attachment_id" @click.stop="openPreview(external_budget)">
                  {{ prepareAttachmentId(external_budget.attachment_id) }}
                </a>
                <span class="grey--text" v-else>{{ __('Nenhum', 'budget') }}</span>
              </td>
              <td>
                {{ external_budget.coverage.type_description }}
              </td>
              <td>
                {{ formatMoney(external_budget.value) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>

    <ExternalBudgetFormDialog
      ref="external_budget_form"
      v-model="budget_dialog"
      :max-width="650"
      @save="handleSubmitForm"
      :loading="submitting"
    />

    <file-preview-dialog
      v-model="open_preview_attachment"
      @change="open_preview_attachment = $event"
      :file="preview_attachment"
      editable
      @handleChangedFile="handleAttachmentChange"
    />
  </v-row>
</template>

<script>
import MoneyFormatter from "@/tools/money";
import { store, update, destroy } from "@/services/external_budgets";

import FilePreviewDialog from "@/components/FilePreviewDialog";
import ExternalBudgetFormDialog from "@/components/Budget/ExternalBudgetFormDialog";
import i18n from '@/mixins/i18n';

export default {
  components: { FilePreviewDialog, ExternalBudgetFormDialog },
  mixins: [i18n],
  props: [
    'external_budgets',
    'silent_loading',
    'fetching_budgets'
  ],
  data() {
    return {
      // Flags
      deleting: false,
      submitting: false,
      select_mode: false,
      budget_dialog: false,
      
      // Budget Selection
      selected_budgets: [],

      open_preview_attachment: false,
      preview_attachment: {}
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    lead_number() {
      return this.$route.params["lead_number"];
    }
  },
  methods: {
    // File Preview
    openPreview(budget) {
      this.open_preview_attachment = true;
      this.preview_attachment = budget.attachment;
    },
    handleAttachmentChange(attachment) {
      this.$listeners.refreshBudgets(true);
      this.preview_attachment = attachment;
    },

    // Budget Selection For DELETE
    handleCancelSelectMode() {
      this.selected_budgets = [];
      this.select_mode = false;
    },
    handleSelectBudget(id) {
      if (this.isBudgetSelected(id)) {
        this.selected_budgets = this.selected_budgets.filter((budget) => budget !== id);
      } else {
        this.selected_budgets.push(id);
      }
    },
    isBudgetSelected(id) {
      return this.selected_budgets.some((budget) => budget === id);
    },

    // Budget Form
    handleEditBudget(budget) {
      if (this.select_mode) return;

      this.$refs.external_budget_form.fillForm(budget);
      this.budget_dialog = true;
    },
    handleOpenNewBudget() {
      this.$refs.external_budget_form.resetForm();
      this.budget_dialog = true;
    },

    // Requests
    async handleSubmitForm(budget) {
      const payload = {
        external_budget: budget,
      };

      this.submitting = true;
      if (budget.id) {
        await update(this.lead_number, budget.id, payload);
      } else {
        await store(this.lead_number, payload);
      }
      this.submitting = false;
      this.budget_dialog = false;
      this.$listeners.refreshBudgets(true);
      this.$listeners.refreshTotals();
    },
    async destroyBudgets() {
      const payload = {
        ids: this.selected_budgets,
      };

      this.deleting = true;
      await destroy(this.lead_number, payload);
      await this.$listeners.refreshBudgets(true);
      await this.$listeners.refreshTotals();
      this.deleting = false;

      this.handleCancelSelectMode();
    },


    prepareAttachmentId(id) {
      if (!id) return id;

      const id_parts = id.split("-");

      return id_parts[0];
    },
    formatMoney(value) {
      return MoneyFormatter.euro(value);
    },
  }
}
</script>

<style>

</style>