<template>
  <v-dialog
    :value="value"
    @change="handleChange"
    persistent
    :max-width="maxWidth"
    eager
  >
    <v-card :disabled="loading">
      <v-app-bar flat>
        <v-card-title class="px-0"> {{ __('Orçamento externo', 'budget') }} </v-card-title>

        <v-spacer />
        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form @submit.prevent="handleSubmit()" class="pa-2">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-card-text>
            <ValidationProvider
              name="participant_id"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                v-model="form.participant_id"
                name="participant_id"
                :label="__('Participante', 'budget')"
                :items="participants"
                item-value="id"
                item-text="contact.name"
                :error-messages="errors"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.contact.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.type.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </ValidationProvider>
            <ValidationProvider
              name="external_budget.policy_coverage_id"
              rules="required"
              v-slot="{ errors }"
            >
              <v-autocomplete
                :label="$capitalize($tc('model.damages_coverage'))"
                :items="available_coverages"
                item-value="id"
                item-text="type_description"
                clearable
                :error-messages="errors"
                v-model="form.policy_coverage_id"
                :disabled="submitting"
              ></v-autocomplete>
            </ValidationProvider>
            <ValidationProvider
              name="type_id"
              rules="required"
              v-slot="{ errors }"
            >
              <v-radio-group
                v-model="form.type_id"
                :label="__('Tipo', 'budget')"
                name="type_id"
                :error-messages="errors"
              >
                <v-radio :label="__('Edifício', 'budget')" :value="1"> </v-radio>
                <v-radio :label="__('Conteúdo', 'budget')" :value="2"> </v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="external_budget.value"
              :rules="{ required: true, min_value: 0.1 }"
              v-slot="{ errors }"
            >
              <vuetify-money
                type="text"
                :options="money"
                v-model="form.value"
                :error-messages="errors"
                :label="$capitalize($tc('model.form_lead_value_money_safe'))"
                :disabled="submitting"
              />
            </ValidationProvider>

            <v-autocomplete
              :label="__('Documento', 'budget')"
              :items="documents"
              item-value="id"
              item-text="name"
              v-model="form.attachment_id"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title> {{ item.name }} </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.category_id ? item.category.description : "" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <ScopeProvider scope="leads.damages.budgets.approve">
              <v-checkbox
                v-model="form.approved"
                :label= "__('Documento', 'budget')"
                :disabled="submitting"
              ></v-checkbox>
            </ScopeProvider>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="handleClose()" text>{{
              $capitalize($tc("model.productbudget_cancel_button"))
            }}</v-btn>
            <v-btn
              type="submit"
              color="primary"
              :loading="loading"
              :disabled="invalid"
            >
              {{ $capitalize($tc("model.productbudget_submit")) }}
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-form>
    </v-card>

    <file-preview-dialog
      v-model="external_budget_dialog"
      @change="external_budget_dialog = $event"
      editable
      :file="form.attachment"
      @handleChangedFile="updateAttachmentStore(budget, $event)"
    />
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { store, update, uploadAttachment } from "@/services/external_budgets";

import ScopeProvider from "@/components/ScopeProvider";
import FilePreviewDialog from "@/components/FilePreviewDialog";
import i18n from '@/mixins/i18n';

export default {
  name: "external-budget-form-dialog",
  components: { ScopeProvider, FilePreviewDialog },
  mixins: [i18n],
  props: {
    value: Boolean,
    budget: Object,
    participant: Object,
    loading: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 1000
    }
  },
  data: function() {
    return {
      external_budget_dialog: false,

      submitting: false,
      form: {},
      loading_files: false,
      documents: [],
      money: {
        prefix: "€ ",
        suffix: "",
        precision: 2,
        length: 11,
        locale: "pt-PT"
      }
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    handleClose() {
      this.$emit("input", false);
      if (this.$listeners.close) this.$listeners.close();
      this.resetForm();
    },
    fillForm(budget) {
      this.form = {
        ...budget
      };
    },
    resetForm() {
      this.form = {};
      if (this.lead && this.participants.length == 1) {
        this.form.participant_id = this.participants[0].id;
      }

      this.$refs.form.reset();
    },
    loadForm() {
      this.form = {
        ...this.form,
        ...this.budget
      };
    },
    async handleSubmit() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      this.$emit("save", this.form);
    },
    async uploadAttachment(budget) {
      const response_upload = await uploadAttachment(
        this.participant.id,
        budget.id,
        this.form.attachment
      );
      this.updateAttachmentStore(budget, response_upload);
    },
    updateAttachmentStore(budget, attachment) {
      this.$store.dispatch("budget/editExternalBudget", {
        ...budget,
        attachment
      });
    },
    async getDocuments(silent = false) {
      this.loading_files = true;
      if (silent == false) {
        this.files = [];
      }
      const response = await this.$http.get(
        "/leads/" + this.$route.params["lead_number"] + "/attachments"
      );
      this.documents = response.data;
      this.loading_files = false;
    }
  },
  computed: {
    lead: function() {
      return this.$store.getters.getLead;
    },
    available_coverages: function() {
      if (!this.lead) return [];

      return this.lead.policy.coverages.filter(item => item.selected === true);
    },
    participants() {
      return (
        this.lead?.participants?.filter(
          participant => participant.insured === true
        ) || []
      );
    }
  },
  created() {
    this.loadForm();
  },
  watch: {
    lead: function(value) {
      if (this.participants.length == 1) {
        this.form.participant_id = this.participants[0].id;
      }
    },
    budget: function(value) {
      if (value) {
        this.loadForm();
      }
    }
  },
  created() {
    if (this.lead && this.participants.length == 1) {
      this.form.participant_id = this.participants[0].id;
    }

    this.getDocuments();
  }
};
</script>

<style></style>
