<template>
  <div>
    <EstablishedBudgets
      :budgets="budgets"
      :silent_loading="silent_budget_loading"
      :fetching_budgets="loading"
      @refreshBudgets="getBudgets"
      @refreshTotals="refreshTotalCards"
    />
    <ExternalBudgets
      :external_budgets="external_budgets"
      :silent_loading="silent_external_budget_loading"
      :fetching_budgets="loading"
      @refreshBudgets="getExternalBudgets"
      @refreshTotals="refreshTotalCards"
    />
    
    <v-row dense>
      <v-col class="d-flex" style="flex-direction:column" cols="12" md="6">
        <BudgetsBusinessEntities
          :budgets_by_business_entity="budget_totals.per_business_entity"
          :silent_loading="silent_budget_totals"
          :fetching_budgets="loading"
        />
      </v-col>
      <v-col class="d-flex" style="flex-direction:column" cols="12" md="6">
        <BudgetTotals
          :budget_totals="budget_totals"
          :silent_loading="silent_budget_totals"
          :fetching_budgets="loading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MoneyFormatter from "@/tools/money";
import { index as indexBudgets, indexBudgetsByCoverages, indexBudgetsTotals } from "@/services/established_budgets";
import { index as indexExternalBudgets,} from "@/services/external_budgets";

import EstablishedBudgets from "@/components/Budget/EstablishedBudgets";
import ExternalBudgets from "@/components/Budget/ExternalBudgets";
// import Franchises from "@/components/Budget/Franchises";
import BudgetsBusinessEntities from "@/components/Budget/BudgetsBusinessEntities";
import BudgetTotals from "@/components/Budget/BudgetTotals";

export default {
  components: { EstablishedBudgets, ExternalBudgets, BudgetsBusinessEntities, BudgetTotals },
  data: () => ({
    tab: 1,

    loading: false,
    silent_budget_loading: false,
    silent_external_budget_loading: false,
    silent_budgets_by_coverages_loading: false,
    silent_budget_totals: false,

    budgets: [],
    external_budgets: [],
    budgets_by_coverages: [],
    budget_totals: {
      per_business_entity: []
    }
  }),
  methods: {
    async refreshTotalCards() {
      await Promise.all([
        this.getBudgetsByCoverages(true),
        this.getBudgetTotals(true)
      ]);
    },
    async getBudgetTotals(silent = false) {
      if (silent) {
        this.silent_budget_totals = true;
      }

      const response = await indexBudgetsTotals(this.lead_number);
      this.budget_totals = response;

      this.silent_budget_totals = false;
    },
    async getBudgetsByCoverages(silent = false) {
      if (silent) {
        this.silent_budgets_by_coverages_loading = true;
      }

      const response = await indexBudgetsByCoverages(this.lead_number);
      this.budgets_by_coverages = response;

      this.silent_budgets_by_coverages_loading = false;
    },
    async getExternalBudgets(silent = false) {
      if (silent) {
        this.silent_external_budget_loading = true;
      }
  
      this.external_budgets = await indexExternalBudgets(this.lead_number);
      this.silent_external_budget_loading = false;
    },
    async getBudgets(silent = false) {
      if (silent) {
        this.silent_budget_loading = true;
      }

      const { data } = await indexBudgets(this.lead_number);
      this.budgets = data;

      this.silent_budget_loading = false;
    },
    formatMoney(value) {
      return MoneyFormatter.euro(value);
    },
  },
  computed: {
    lead: function () {
      return this.$store.getters.getLead;
    },
    lead_number: function () {
      return this.$route.params["lead_number"];
    },
  },
  async created() {
    if (this.lead) {
      if (this.lead.participants.length === 1) {
        this.input_participant_id = this.lead.participants[0].id;
      }
    }

    this.loading = true;
    await Promise.all([
      this.getBudgets(), 
      this.getExternalBudgets(), 
      this.getBudgetsByCoverages(), 
      this.getBudgetTotals()
    ]);
    this.loading = false;
  },
};
</script>